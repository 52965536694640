import styled from 'styled-components';
import { rem } from '../../helpers/rem-converter';
import { mobile } from '../../styles/themes/media';

export const WhiteBackground = styled.div`
    background-color: ${({ theme }) => theme.backgroundPrimary};
    min-height: calc(100vh - ${rem(152)});
    margin-bottom: ${rem(80)};

    ${mobile`
         margin-bottom: ${rem(48)};
    `};
`;
