import styled from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { color } from '../../../styles/tokens/color';

interface StyledLinkProps {
    hover?: boolean;
    textColor?: string;
    hoverUnderline?: boolean;
}

export const StyledLink = styled.a<StyledLinkProps>`
    text-decoration: none;
    cursor: pointer;
    color: ${(p) => p.textColor || color.textLightPrimaryLink};
    font-size: ${rem(17)};
    border-bottom: 1px solid transparent;

    &:hover {
        border-bottom: ${(p) => p.hoverUnderline && `1px solid ${p.textColor || color.textLightPrimaryLink}`};
    }
`;
