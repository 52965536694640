import * as React from 'react';
import { NextPage } from 'next';
import PageNotFound from '../components/containers/page-not-found/page-not-found';
import Container from '../components/layout/container';
import { WhiteBackground } from '../components/layout/white-background';

const Page404: NextPage = () => {
    return (
        <WhiteBackground>
            <Container>
                <PageNotFound />
            </Container>
        </WhiteBackground>
    );
};

export default Page404;
